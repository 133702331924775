<template>
  <div class="content-wrap">
    <div class="create-header">
      <div class="header-top">
        <span>{{ title }}</span>
        <div></div>
      </div>
      <div class="header-bottom">
        <el-form ref="contractForm" :model="formInfo" :rules="rules" label-width="140px">
          <el-row>
            <el-col :span="8">
              <!-- <el-form-item label="所属组织" prop="orgName">
                <el-input v-model.trim="formInfo.orgId" v-show="false" maxlength="128"/>
                -- <organization-select :model="formInfo" :disabled="propIsDisabled(formInfo, 'orgId')" allLabel="请选择所属组织"></organization-select> --
                <el-input v-model.trim="formInfo.orgName" maxlength="128"/>
              </el-form-item> -->
              <el-form-item prop="orgId" required label="所属组织">
                <organization-select :model="formInfo" :filterable="true" :disabled="handIsDisabled()" all-label="请选择所属组织" :is-add-all="false" ></organization-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
             <el-form-item
                class="select-width"
                label="合同类型"
                prop="contractType"
                :required="true"
              >
                <el-select v-model="formInfo.contractType" placeholder="合同类型" clearable style="width: 220px">
                  <el-option v-for="type in contractTypeData" :key="type.value" :label="type.label" :value="type.value"/>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="合同状态 "
                prop="status"
                :required="true"
              >
              <el-select v-model="formInfo.status" @change="changeNoYes">
                <el-option
                  v-for="(item,index) in statusOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="项目名称"
                prop="projectId"
                :required="true"
              >
                <el-input v-model="formInfo.projectId" v-show="false" ></el-input>
                <el-input v-model="formInfo.projectName" @click.native="selectCategoryFlag" readonly ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
             <!-- <el-form-item
                class="select-width"
                label="项目编码"
                prop="projectCode"
              >
                <el-input v-model="formInfo.projectCode" ></el-input>
              </el-form-item> -->
            </el-col>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="不征税合同"
                prop="isNonTaxable"
                :required="true"
              >
              <el-select v-model="formInfo.isNonTaxable" @change="changeNoYes">
                <el-option
                  v-for="(item,index) in options"
                  :key="index"
                  :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
             <el-form-item
                class="select-width"
                label="合同编码"
                prop="contractCode"
                :required="true"
              >
                <el-input v-model="formInfo.contractCode" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="合同名称"
                prop="contractName"
                :required="true"
              >
                <el-input v-model="formInfo.contractName" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="是否为框架合同"
                prop="isStructure"
                :required="true"
              >
              <el-select v-model="formInfo.isStructure" @change="changeNoYes">
                <el-option
                  v-for="(item,index) in options"
                  :key="index"
                  :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item label="合同金额(含税)" prop="allAmount" :required="true">                
                <el-input-number
                  v-model="formInfo.allAmount"
                  :min="0"
                  :controls="false"
                  :precision="2"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="合同金额(不含税)" :required="true">
                <el-input-number
                  v-model="formInfo.allAmountExcludTax"
                  :min="0"
                  :controls="false"
                  :precision="2"
                  style="width: 100%;"
                  controls-position="right"
                  type="number"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="是否含税金额计税"
                prop="isIncludeTaxAmount"
                :required="true"
              >
              <el-select v-model="formInfo.isIncludeTaxAmount" @change="changeNoYes">
                <el-option
                  v-for="(item,index) in options"
                  :key="index"
                  :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="合同签订日期"
                prop="writeTime"
                :required="true"
              >
                <el-date-picker v-model="formInfo.writeTime" value-format="yyyy-MM-dd" 
                  placeholder="选择合同签订日期" type="date" >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="生效日期"
                prop="contractStartTime"
                :required="true"
              >
                <el-date-picker v-model="formInfo.contractStartTime" value-format="yyyy-MM-dd" 
                  placeholder="选择合生效日期" type="date" >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="截止日期"
                prop="contractEndTime"
                :required="true"
              >
                <el-date-picker v-model="formInfo.contractEndTime" value-format="yyyy-MM-dd" 
                  placeholder="选择合同截止日期" type="date" >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <el-form-item label="相对方纳税人识别号" prop="customerNsrsbh" :required="true" label-width="150px">
                <el-input v-model="formInfo.customerNsrsbh" maxlength="32"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="相对方名称"  :required="true">
                <el-input v-model="formInfo.relativeName"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                class="select-width"
                label="印花税税目"
                prop="stampDuty"
              >
                <el-input v-model="formInfo.stampDuty" placeholder=""></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          
          <el-row>
            <el-col :span="16">
              <el-form-item label="备注">
                <el-input type="textarea" v-model="formInfo.remark"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="印花税缴税状态" 
                prop="isStamp"
                :required="true">
                <el-select v-model="formInfo.isStamp" @change="changeNoYes">
                  <el-option
                    v-for="(item,index) in stampOptions"
                    :key="index"
                    :label="item.label"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="create-main">
      <div class="header-top">
        <span>合同附件</span>
      </div>
      <div class="license-body">
        <div class="license-img">
          <el-upload
            action=""
            :before-upload="beforeUpload"
            :show-file-list="false"
            accept="image/jpeg,image/png,image/jpg,.xls,.xlsx,.tsv,.pdf"
            :on-change="handleUploadContractFile">
            <img class="license-show" v-if="formInfo.contractFileUrl!=null&&formInfo.contractFileUrl!='' && !formInfo.contractFileUrl.indexOf('pdf')" :src="formInfo.contractFileUrl">
            <img v-else src="@/assets/icon/upload@2x.png">
          </el-upload>
        </div>
      </div>
    </div>

    <div class="create-main">
      <div class="header-top">
        <span>合同金额变更明细</span>
      </div>
      <div class="license-body">
        <el-table ref="contractInfoTable" :data="tableData" :header-cell-style="handleHeaderCellStyle" border stripe >
          <el-table-column type="index" label="序号" width="60">
          </el-table-column>
          <el-table-column
            prop="status"
            label="合同状态"
            align="center"
            min-width="120"
            :formatter="statusFormat"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="created"
            label="变更日期"
            align="center"
            min-width="120"
            :formatter="handleDateFormat"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="allAmount"
            label="发生金额（含税）"
            align="center"
            min-width="120"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="allAmountExcludTax"
            label="发生金额（不含税）"
            align="center"
            min-width="120"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="isStamp"
            label="印花税缴税状态"
            align="center"
            min-width="120"
            :formatter="stampFormat"
            :show-overflow-tooltip="true"
          ></el-table-column>
        </el-table>
    </div>
    </div>
    <div class="edit-select">
      <div class="edit-select-foot">
        <el-button class="el-button-ext" @click="handleCancel">取消</el-button>
        <el-button class="el-button-ext" v-loading="saveBtnLoading" @click="handleSaveContract" type="primary">保存</el-button>
      </div>
    </div>

    <el-dialog v-if="categoryFlag" title="选择项目信息" :visible.sync="categoryFlag"
            :close-on-click-modal="false"
            append-to-body
            width="70%">
      <project-dialog @showCategory="showCategory" @selectGoods="selectGoods"></project-dialog>
    </el-dialog>

  </div>
</template>

<script>
import projectDialog from './projectDialog'
import {tableStyle} from '@/util/mixins';
import RegexLib from '@/assets/js/regex-lib'
import OrganizationSelect from '@/components/widgets/OrganizationSelect';
import {
  getContract,
  saveContract,
  searchContractChangeList
} from '@/service/contract'
// import {getInfosByOrgId} from '@/service/paas/paas-service'

export default {
  name: 'create',
  components: { OrganizationSelect, projectDialog },
  mixins: [tableStyle],
  props: {
    lockObject: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isEdit: {
      type: Boolean,
      // default: false
    }

  },
  data () {
    return {
      categoryFlag: false, // 项目管理弹窗
      elecData: [
        {
          value: true,
          label: '是'
        },
        {
          value: false,
          label: '否'
        }
      ],
      contractTypeData: [
        {label: '收入类', value: 1},
        {label: '支出类', value: 2},
      ],
      tableData: [],
      // chanelData: [
      //   {
      //     billingChannel: 'QD-RPA',
      //     billingChannelName: '全电-RPA',
      //     enabled: false,
      //     appId: '',
      //     appSecret: '',
      //     applyType: false
      //   },
      //   {
      //     billingChannel: 'QD-XSD',
      //     billingChannelName: '机器人X版',
      //     enabled: false,
      //     appId: '',
      //     appSecret: '',
      //     applyType: false
      //   },
      //   {
      //     billingChannel: 'QD-LQ',
      //     billingChannelName: '全电-乐企',
      //     enabled: false,
      //     appId: '',
      //     appSecret: '',
      //     applyType: false
      //   },
      // ],

      //印花税缴税状态
      stampOptions: [
        {
          value: 0,
          label: '未缴税'
        }, {
          value: 1,
          label: '已缴税'
        }
      ],
      statusOptions: [
        {
          value: 1,
          label: '正常'
        }, {
          value: 2,
          label: '终止'
        }
      ],
      options: [
        {
          value: 0,
          label: '否'
        }, {
          value: 1,
          label: '是'
        }
      ],
      title: '合同信息',
      formInfo: this.lockObject,
      contractFile: '',
      rules: {
        // name: [
          // { required: true, message: '请输入组织名称', trigger: 'blur' },
        //   { min: 1, max: 128, message: '长度在 1 到 128 个字符', trigger: 'blur' },
        //   { 'validator': this.handleCheckprojectName, trigger: 'blur' }
        // ],
        isStamp: [
          { required: true, message: '请选择印花税缴税状态', trigger: 'blur' },
        ],
      },
      saveBtnLoading: false
    }
  },
  created () {
    // this.isEdit = this.$route.query.isEdit
    // if (this.isEdit) {
    //   this.title = '编辑组织'
    //   let id = this.$route.query.id
    //   this.handleSelectproject(id)
    //   this.renderTable();
    // // } else {
    // //   this.tableData = this.chanelData
    // }
      // let id = this.$route.query.id
      // let id = this.formInfo.id
      // this.handleSelectproject(id)
      this.renderTable();

    if (this.formInfo.id) {
      this.getContractById();
    }
  },
  computed: {
    ruless: function () {
      return this.rules
    }
  },
  methods: {
    async renderTable() {
      this.tableData = [];
      const {success, data} = await searchContractChangeList({
        contractId: this.formInfo.id
      });
      if (success && data.length > 0) {
        this.tableData = data
      // } else {
      //   this.tableData = this.chanelData
      }
    },
    async getContractById() {
      const {success, data} = await getContract({
        id: this.formInfo.id
      });
      if (success && null != data) {
        this.formInfo = data
      }
    },
    beforeUpload(file) {
      // 阻止默认的上传行为
      return false;
    },
    // 合同状态
    statusFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '合同状态':
          if (value === 1) {
            return '正常';
          } else if (value === 2) {
            return '终止';
          } else {
            return '';
          }
      }
    },
    // 印花税缴税状态
    stampFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '印花税缴税状态':
          if (value === 0) {
            return '未缴税 ';
          } else if (value === 1) {
            return '已缴税';
          } else {
            return '';
          }
      }
    },
    //上传合同附件
    handleUploadContractFile (file) {
      this.formInfo.contractFileUrl = URL.createObjectURL(file.raw)
      this.contractFile = file.raw
    },
    changeNoYes() {
      this.$refs.contractForm.clearValidate()
    },
    // 点击取消的回调
    handleCancel(status) {
      this.$emit('handleCancelEditDialog', status);
    },
    // 点击提交 -> 参数校验
    handleSaveContract() {
      this.$refs.contractForm.validate((valid) => {
        if (valid) this.submitContract();
      });
      // this.submitContract();
    },
    // 
    handIsDisabled() {
      return this.isEdit;
    },
    // 提交合同信息
    async submitContract () {
        //默认值
        // if (!this.organization.isElec) {
        //   this.organization.isElec = false
        // }
        // let org = this.organization
        // 参数校验
        // if (this.organization.isTaxpayer === 'Y') {
        //   let nameCheck = (org.name.length === 0)
        //   let taxNoCheck = (org.taxNo.length === 0)
        //   if (nameCheck || taxNoCheck) {
        //     this.toast('组织名称或者组织税号不能为空！', 'warning')
        //     return
        //   }
        // } else {
        //   let nameCheck = (org.name.length === 0)
        //   if (nameCheck) {
        //     this.toast('组织名称不能为空！', 'warning')
        //     return
        //   }
        // }
        
        let form = new FormData()
        if (null != this.formInfo.id) {
          form.append('id', this.formInfo.id.toString())
        }

        if (null != this.formInfo.projectId) {
          form.append('projectId', this.formInfo.projectId.toString())
        }

        form.append('projectCode', this.formInfo.projectCode)
        form.append('projectName', this.formInfo.projectName)
        form.append('contractCode', this.formInfo.contractCode)
        form.append('contractName', this.formInfo.contractName)
        form.append('orgId', this.formInfo.orgId)
        form.append('orgName', this.formInfo.orgName)
        form.append('contractType', this.formInfo.contractType)
        form.append('status', this.formInfo.status)
        form.append('isNonTaxable', this.formInfo.isNonTaxable)
        form.append('isStructure', this.formInfo.isStructure)
        form.append('allAmount', this.formInfo.allAmount)
        form.append('allAmountExcludTax', this.formInfo.allAmountExcludTax)
        form.append('isIncludeTaxAmount', this.formInfo.isIncludeTaxAmount)

        if (null != this.formInfo.writeTime && '' != this.formInfo.writeTime) {
          form.append('writeTime', new Date(this.formInfo.writeTime))
        }

        if (null != this.formInfo.contractStartTime && '' != this.formInfo.contractStartTime) {
          form.append('contractStartTime', new Date(this.formInfo.contractStartTime))
        }

        if (null != this.formInfo.contractEndTime && '' != this.formInfo.contractEndTime) {
          form.append('contractEndTime', new Date(this.formInfo.contractEndTime))
        }

        form.append('customerNsrsbh', this.formInfo.customerNsrsbh)
        form.append('relativeName', this.formInfo.relativeName)
        form.append('isStamp', this.formInfo.isStamp)
        
        if (null != this.formInfo.stampDuty && '' != this.formInfo.stampDuty) {
          form.append('stampDuty', this.formInfo.stampDuty)
        }
        if (null != this.formInfo.stampSubDuty && '' != this.formInfo.stampSubDuty) {
          form.append('stampSubDuty', this.formInfo.stampSubDuty)
        }
        
        if (null != this.formInfo.remark && '' != this.formInfo.remark) {
            form.append('remark', this.formInfo.remark)
        }

        if (null != this.formInfo.contractFilePath && '' != this.formInfo.contractFilePath) {
          form.append('contractFilePath', this.formInfo.contractFilePath)
        }
        if (this.contractFile.length != 0) {
          form.append('contractFile', this.contractFile)
        }

        const { success, data } = await saveContract(form)

        const message = this.formInfo.id > 0 ? '修改' : '新增';
        if (success) {
          this.toast(`${message}合同信息成功`, 'success', () => this.handleCancel('success'));
        } else {
          if (null != data) {
            this.toast(`${message}合同信息失败` + data, 'error');
          }else {
            this.toast(`${message}合同信息失败`, 'error');
          }
        }
    },
    selectCategoryFlag() {
      this.categoryFlag = true;
    },
    showCategory() {
      this.categoryFlag = !this.categoryFlag;
    },
    selectGoods(item) {
      this.formInfo.projectId = item.id;
      this.formInfo.projectName = item.projectName;
    },
    /* 时间格式化 */
    handleDateFormat(date) {
      if (date == undefined) {
        return '';
      }
      return this.$moment(date).format('YYYY-MM-DD');
    },
    // 禁止输入特殊字符
    handleNoInputSpecialCharacter (e) {
      let flag = RegexLib.TaxNo
      e.target.value = e.target.value.replace(flag, '')
    },
    // 提交合同信息
    // async submitContract() {
    //   // loading保存按钮阻止反复提交
    //   this.saveBtnLoading = true;
    //   const { success } = await saveContract(this.formInfo);

    //   const message = this.formInfo.id > 0 ? '修改' : '新增';
    //   this.saveBtnLoading = false;
    //   if (success) {
    //     this.toast(`${message}合同信息成功`, 'success', () => this.handleCancel('success'));
    //     if (this.scene == 'Billing' && this.isRetail) {
    //       this.$emit('edit-finish', this.info);
    //     }
    //   } else {
    //     this.toast(`${message}合同信息失败`, 'error');
    //   }
    // },
  }
}
</script>
<style lang="scss" scoped>
  .create-header {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);

    .header-top {
      padding: 16px 24px;
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid #e9e9e9;
      color: #333;
      display: flex;

      div {
        width: 406px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #F5222D;
        line-height: 20px;
        margin-left: 16px;
      }

      span:before {
        content: "|";
        display: inline-block;
        font-size: 16px;
        margin-right: 10px;
        background-color: #333;
      }
    }

    .header-bottom {
      margin-bottom: 24px;

      .el-row {
        margin: 24px 0px;

        &:last-child {
          padding-bottom: 20px;
        }
      }

      label {
        width: 70px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 20px;
      }

      span {
        width: 56px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 20px;
      }
    }
  }

  .create-main {
    width: 100%;
    height: 365px;
    background-color: #fff;
    overflow-x: auto;
    margin-bottom: 24px;

    .header-top {
      padding: 16px 24px;
      font-size: 16px;
      font-weight: 500;
      border-bottom: 1px solid #e9e9e9;
      color: #333;
      display: flex;

      div {
        width: 406px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #F5222D;
        line-height: 20px;
        margin-left: 16px;
      }

      span:before {
        content: "|";
        display: inline-block;
        font-size: 16px;
        margin-right: 10px;
        background-color: #333;
      }
    }

    .license-title {
      height: 40px;
      margin: 24px;
      background: rgba(235, 244, 255, 1);
      display: flex;
      align-items: center;
      padding-left: 24px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 3px;
      }

      span {
        width: 1082px;
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
      }
    }

    .license-body {
      display: flex;
      flex-direction: column;
      align-items: center;

      .license-img {
        width: 180px;
        height: 240px;
        background: rgba(229, 229, 229, 1);
        margin-bottom: 48px;
        display: flex;
        justify-content: center;
        align-items: center;

        .license-show {
          width: 180px;
          height: 240px;
        }

        img {
          width: 58px;
          height: 58px;
        }
      }

      .el-button {
        width: 120px;
      }
    }
  }

  .header-top {
    padding: 16px 24px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #e9e9e9;
    color: #333;

    span:before {
      content: "|";
      display: inline-block;
      font-size: 16px;
      margin-right: 10px;
      background-color: #333;
    }
  }

  .edit-select {
    height: 60px;
  }

  .el-input-number {
    width: 200px;
    
    .el-input__inner {
      text-align: right;
    }

  }
</style>
<style>
  .el-form-item__error {
    top: 30px;
  }
</style>
